import { mapKLMCookieToPermissions } from '/cookieconsent/mapKLMCookieToPermissions'
import { cookieConsentEvent } from '/machinery/tracking/cookieConsent'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

export default function RegisterCallbackToCookiePopUp() {
  React.useEffect(
    () => {
      if (typeof window?._st?.cookiepermission?.setCookiePreferences === 'function') return

      window._st = {}
      window._st.cookiepermission = {}
      window._st.cookiepermission.setCookiePreferences = function(permissions) {
        const mappedPermissions = mapKLMCookieToPermissions(permissions)
        pushToDataLayer(cookieConsentEvent({ permissions: mappedPermissions }))
      }
    },
    []
  )

  return <div />
}
