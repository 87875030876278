export function mapKLMCookieToPermissions(parsedCookiePermissions) {
  if (Object.keys(parsedCookiePermissions).length === 0) { return getDefaultCookieState() }

  return {
    functionality_storage: parsedCookiePermissions?.['1'] ? 'granted' : 'denied',
    analytics_storage: parsedCookiePermissions?.['1'] ? 'granted' : 'denied',
    ad_storage: parsedCookiePermissions?.['3'] ? 'granted' : 'denied',
    personalization_storage: parsedCookiePermissions?.['3'] ? 'granted' : 'denied',
    security_storage: parsedCookiePermissions?.['1'] ? 'granted' : 'denied',
    ...additionalPermissions()
  }
}

function additionalPermissions() {
  return {
    ad_personalization: 'denied',
    ad_user_data: 'denied',
  }
}

function getDefaultCookieState() {
  return {
    functionality_storage: 'granted',
    analytics_storage: 'granted',
    ad_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    ...additionalPermissions()
  }
}
